@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraBold"; /*Can be any text*/
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Sunborn"; /*Can be any text*/
  src: local("Sunborn"),
    url("./assets/fonts/Sunborn/Sunborn-SansOne.otf") format("truetype");
}

body,
html {
  font-family: "Sunborn", "Bold", "ExtraBold", "Medium", "Light" !important;
}

code {
  font-family: "Light", "Medium", "ExtraBold" !important;
}
